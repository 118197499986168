<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div class="col-md-6">
                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                        <input type="text" placeholder="business name" class="form-control" v-model="data.name">
                    </div>
                </div>
<!--                <div class="col-md-6">-->
<!--                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">-->
<!--                        <select class="form-control" v-model="data.status">-->
<!--                            <option value="" selected disabled>Select Status</option>-->
<!--                            <option value="active" hidden>Active</option>-->
<!--                            <option value="inactive">Inactive</option>-->
<!--                        </select>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddFormElement',
    props: {
        data: {
            type: Object
        },
    }
}
</script>
<style scoped>
.journal-line {
    border: 1px solid #f0f2f5;
}

.journal-line:hover {
    background: #f0f2f5;
}
</style>
