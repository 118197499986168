<template>
    <div style="min-height: calc(100vh - 158px);">
        
        <div :style="{opacity: loading ? '.5' : '1'}" class="card p-2 mt-3">
            <TitleButton
                btnTitle="Go Back"
                title="Create Business"
                @onClickAddNewButton="goToList"
            />
            <hr>
            <AddFormElement
                class="mb-2"
                :data="business"
            />
            
            <div class="mt-2 text-center">
                <hr class="mb-1">
                <button
                    @click="saveBusiness(true)"
                    class="btn btn-primary me-1"
                >
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
                <button
                    @click="goToList"
                    class="btn btn-outline-secondary cancel-btn"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from 'vue'
// import { computed } from 'vue'
// import {mapMutations} from 'vuex'
import handleCBusinesses from '@/services/modules/businesses'

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/business/AddFormElement'
import AddButton from '@/components/atom/AddButton'

export default {
    name: 'CreateBusiness',
    components: {
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        business: {
            name: '',
            status: '',
            company_id: null,
            user_id: null,
            updated_by: null,
        },
    }),
    methods: {
        goToList() {
            this.$router.push({
                name: 'business-list',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
                
            })
        },
        resetForm() {
            this.business = {
                name: '',
                status: '',
                company_id: null,
                user_id: null,
                updated_by: null,
            }
        },
        getFormData() {
            let formData = new FormData();
            Object.keys(this.business).forEach(i => {
                formData.append(i, this.business[i])
            })
            return formData;
        },
        async saveBusiness(redirect = false) {//createJournal
            this.business.company_id = this.$route.params.companyId;
            this.business.user_id = this.$store.getters["user/getUser"].id
            this.business.updated_by = this.$store.getters["user/getUser"].id
            let data = this.getFormData();
            if (redirect) {
                this.loading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createNewBusiness(data)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.showSuccess(res.message)
                    this.resetForm()
                    if (redirect) this.goToList()
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
                this.saveNewLoader = false
            }
        },
    },
    setup() {
        const showError = inject('showError');
        const showSuccess = inject('showSuccess');
        
        const {
            createNewBusiness,
            loading,
        } = handleCBusinesses()
        
        return {
            showError,
            showSuccess,
            loading,
            createNewBusiness,
        }
    },
}
</script>

<style scoped>
.cancel-btn {
    background-color: #F0F2F5;
    color: #7D7D7D;
}

.cancel-btn:hover {
    background-color: #e9edf5;
}

hr {
    margin: 0 -3%;
    width: 106%;
}
</style>
